<template>
  <v-card
    class="mt-3 d-flex flex-column"
    height="100%"
  >
    <v-img
      v-if="!noThumb && imageThumb"
      class="mb-3"
      :src="imageThumb"
    />

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-start overflow-x-hidden"
        >
          <p class="">
            {{ fileObj.original_name }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          class="d-flex justify-start"
        >
          {{ formatWeight(fileObj.size) }}
        </v-col>
        <v-col
          class="d-flex justify-end"
        >
          <file-menu
            v-if="canHandleFile && uploadEnabled"
            @deleteFile="isShow.delete=true"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
      <v-btn
        :color="btnColor"
        :dark="btnDark"
        block
        small
        :loading="loading.download"
        @click="downloadWithAxios(fileObj.original_name); loading.download=true;"
      >
        Download
      </v-btn>
    </v-card-actions>
    <delete-file
      is-plugin-version
      :is-show="isShow.delete"
      :document-file-data="fileObj"
      @reloadData="reloadData"
      @closeModal="isShow.delete=false"
    />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import DeleteFile from '@/components/modals/delete-file.vue'
import FileMenu from '@/components/menus/file-menu.vue'

export default {
  components: {
    DeleteFile,
    FileMenu,
  },
  props: {
    noThumb: {
      type: Boolean,
      default: false,
    },
    fileObj: {
      type: Object,
      required: true,
    },
    uploadEnabled: {
      type: Boolean,
      default: true,
    },
    downloadUrl: {
      type: String,
      required: true,
    },
    procedureId: {
      type: Number,
      required: true,
    },
    isPluginVersion: {
      type: Boolean,
      default: false,
    },
    canHandleFile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: {
        delete: false,
        upload: false,
      },
      documentFiles: {},
      finalDocumentFiles: {},
      loading: {
        download: false,
      },
      imageThumb: '',
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'getImagesRepo', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
  },
  async mounted() {
    // check if image path already exists in obj
    if (!this.noThumb && !this.imageThumb) {
      await this.getImagePath()
    }
  },
  methods: {
    async reloadData() {
      this.$emit('reloadData')
    },

    // CREARE UN HELPER PER TUTTO QUESTO
    async getImagePath() {
      try {
        if (this.getImagesRepo.procedureFilesThumb[this.fileObj.id]) {
          const thumbTmp = this.getImagesRepo.procedureFilesThumb[this.fileObj.id]
          const date = new Date()
          if (Math.floor(date.getTime() / 1000) > thumbTmp.expires) {
            await this.askImagePath()
          } else {
            this.imageThumb = thumbTmp.url
          }
        } else {
          await this.askImagePath()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async askImagePath() {
      try {
        let resp = ''
        if (this.isPluginVersion) {
          resp = await this.$api.getPluginDocumentFileThumb(this.procedureId, this.fileObj.document_id, this.fileObj.id)
        } else {
          resp = await this.$api.getDocumentFileThumb(this.procedureId, this.fileObj.document_id, this.fileObj.id)
        }

        this.imageThumb = resp.data.img

        await this.$store.dispatch('setImageRepo', {
          repo: 'procedureFilesThumb',
          id: this.fileObj.id,
          imageUrl: resp.data.img,
          expires: resp.data.expires,
        })
      } catch (error) {
        console.log(error)
      }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(title) {
      const url = this.downloadUrl
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.loading.download = false
        })
        .catch(error => {
          switch (error.response.status) {
            case 403:
              this.$store.dispatch('set_alert', {
                msg: 'Non hai i permessi necessari per scaricare il file.',
                type: 'error',
              })
              break
            default:
              this.$store.dispatch('set_alert', {
                msg: 'Errore nel download, si prega di riprovare.',
                type: 'error',
              })
              break
          }
          this.loading.download = false
        })
    },
    formatWeight(size) {
      let finalSize = size / 1000

      // mega
      if (finalSize > 1000) {
        finalSize /= 1000

        return `${finalSize.toFixed(2)} MB`
      }

      return `${finalSize.toFixed(0)} KB`
    },
  },
}
</script>

<style scoped>

</style>
